@use 'sass:math';
@import '/src/scss/style.scss';
@import '/src/scss/mixins';
@import '/src/scss/colors-palette.scss';
.edge {
  background: #94a7b2;
  padding: rem(150) 0;
  overflow: hidden;

  @media (max-width: 991px) {
    padding: rem(55) 0 0;
  }

  &.yellow {
    background: #b2a694;
  }

  &__img {
    margin: rem(10) 0 0;
    height: rem(240);

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }

  &__title {
    position: relative;
    z-index: 2;
  }
  &Body {
    position: relative;
    z-index: 1;
    margin: rem(70) 0 0;

    @media (max-width: 991px) {
      margin: rem(35) 0 0;
    }

    &__img {
      position: absolute;
      z-index: -1;
      top: rem(-75);
      left: rem(300);

      width: rem(1070);

      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }

    &__row {
      display: flex;

      @media (max-width: 991px) {
        flex-direction: column;
      }

      &:nth-child(1) {
      }
      &:nth-child(2) {
        .card {
          position: relative;

          &:nth-child(1) {
            &::after {
              content: '';
              position: absolute;
              background-color: rgba(255, 255, 255, 0.5);
              height: rem(1);
              left: 0;
              right: 0;
              bottom: rem(-1);
              @media (max-width: 991px) {
                display: none;
              }
            }
          }
        }
      }
      &:nth-child(3) {
        justify-content: flex-end;
        .card {
          &:nth-child(1) {
            position: relative;
            &::after {
              content: '';
              position: absolute;
              background-color: rgba(255, 255, 255, 0.5);
              width: rem(1);
              left: rem(-1);
              top: 0;
              bottom: 0;

              @media (max-width: 991px) {
                display: none;
              }
            }
          }
          &:nth-child(2) {
            border-right: none;
          }
        }
      }
    }
  }
}

.card {
  flex: 0 0 33.333%;

  display: flex;
  flex-direction: column;
  border: rem(1) solid rgba(255, 255, 255, 0.5);
  border-bottom: none;
  border-left: none;
  background-color: rgba($color: #94a7b2b2, $alpha: 0.7);
  backdrop-filter: blur(rem(10));

  padding: rem(42) rem(20);
  height: rem(344);

  &.yellow {
    background-color: rgba($color: #b2a694b2, $alpha: 0.7);
  }

  @media (max-width: 991px) {
    flex: 1 1 100%;
    height: auto;
    border: none !important;
    border-top: rem(1) solid rgba(255, 255, 255, 0.5) !important;
    padding: rem(35) 0;
    flex-direction: row-reverse;
    gap: rem(25);
  }

  &Content {
    flex: 1 1 auto;
    max-width: rem(280);

    @media (max-width: 991px) {
      max-width: 100%;
    }

    &__title {
      @media (max-width: 991px) {
        br {
          display: none;
        }
      }
    }
    &__text {
      margin: rem(20) 0 0;

      @media (max-width: 991px) {
        margin: rem(10) 0 0;
      }
    }
  }
  &__num {
  }
}
