@use 'sass:math';
@import '/src/scss/style.scss';
@import '/src/scss/mixins';
@import '/src/scss/colors-palette.scss';

.partnership {
  background: #fff;
  padding: rem(150) 0;

  @media (max-width: 991px) {
    padding: rem(55) 0;
  }

  &__title {
    display: flex;
    flex-wrap: wrap;

    @media (max-width: 991px) {
      br {
        display: none;
      }
    }

    div {
      display: flex;
      flex-wrap: wrap;
      flex: 1 1 100%;

      @media (max-width: 991px) {
        flex: 1 1 auto;
        display: contents;
      }

      i {
        display: block;
        width: rem(50);

        @media (max-width: 991px) {
          display: none;
        }
      }
    }
  }
  &__text {
    max-width: rem(710);
    margin: rem(40) 0 0;

    @media (max-width: 991px) {
      margin: rem(10) 0 0;
    }
  }
  &Body {
    display: flex;
    margin: rem(80) 0 0;

    @media (max-width: 991px) {
      margin: rem(35) 0 0;
      flex-wrap: wrap;
    }

    &__card {
      flex: 0 0 25%;
      border-top: rem(1) solid rgba(111, 135, 150, 0.5);

      &:not(:first-child) {
        border-left: rem(1) solid rgba(111, 135, 150, 0.5);
      }

      &:hover {
        img {
          opacity: 0.6;
          transform: scale(0.9);
        }
      }

      @media (max-width: 991px) {
        flex: 0 0 50%;

        &:nth-child(odd) {
          border-left: none;
        }
      }

      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
        transition: all $time $tr;
      }
    }
  }
}
