@use 'sass:math';
@import '/src/scss/style.scss';
@import '/src/scss/mixins';
@import '/src/scss/colors-palette.scss';

.title {
  font-family: 'Sora', sans-serif;
  font-weight: 500;
  line-height: 130%;
  color: #fff;

  &.blue {
    color: #6f8796;
  }
  &.black {
    color: #14181a;
  }
  &.grey {
    color: #6f8796;
  }
  &.yellow {
    color: #96866f;
  }

  &.h1 {
    font-size: rem(77);
    line-height: 115%;

    @media (max-width: 991px) {
      font-size: rem(29);
    }
  }
  &.h2 {
    font-size: rem(52);
    line-height: 115%;

    @media (max-width: 991px) {
      font-size: rem(22);
    }
  }
  &.h3 {
    font-size: rem(27);
    @media (max-width: 991px) {
      font-size: rem(16);
    }
  }

  span {
    color: #6f8796;
  }
}
