@use 'sass:math';
@import '/src/scss/style.scss';
@import '/src/scss/mixins';
@import '/src/scss/colors-palette.scss';

.button {
  cursor: pointer;

  padding: rem(10) rem(25);
  border-radius: rem(5);
  background: #fff;

  font-weight: 400;
  font-size: rem(19);
  // line-height: 140%;
  line-height: normal;
  text-align: center;
  color: #1d2326;

  transition: all 0.3s ease;

  &:hover {
    color: #fff;
    background: #1d2326;
  }

  &.sm {
    font-size: rem(16);
    padding: rem(4) rem(15);

    @media (max-width: 991px) {
      font-size: rem(14);
      padding: rem(3) rem(11);
    }
  }

  &.blue {
    color: #fff;
    background: #6f8796;

    &:hover {
      color: #14181a;
      background: #fff;
    }
  }

  &.border {
    border: rem(1) solid #6f8796;
    background-color: transparent;
    color: #6f8796;
    border-radius: rem(5);

    @media (max-width: 991px) {
      border-radius: rem(5);
      border: rem(1) solid #6f8796;
    }

    &:hover {
      color: #fff;
      background: #6f8796;
      border-color: #6f8796;
    }
  }
}
