@use 'sass:math';
@import '/src/scss/style.scss';
@import '/src/scss/mixins';
@import '/src/scss/colors-palette.scss';

.team {
  padding: rem(150) 0;
  background-color: #fff;

  @media (max-width: 991px) {
    padding: rem(55) 0;
  }

  &__title {
  }

  &Body {
    display: flex;
    flex-direction: column;
    gap: rem(70);
    margin: rem(80) 0 0;
    padding: rem(70) 0 0 rem(315);
    border-top: rem(1) solid rgba(111, 135, 150, 0.5);

    @media (max-width: 1200px) {
      padding: rem(70) 0 0 rem(0);
    }
    @media (max-width: 991px) {
      margin: rem(35) 0 0;
      padding: rem(35) 0 0;
      gap: rem(35);
    }
  }
}

.card {
  display: flex;
  gap: rem(35);
  padding: 0 0 rem(70);
  border-bottom: rem(1) solid rgba(111, 135, 150, 0.5);

  @media (max-width: 991px) {
    position: relative;
    flex-direction: column;
    gap: rem(30);
    padding: 0 0 0 rem(144);

    &:last-child {
      border: none;
    }
    &:not(:last-child) {
      padding: 0 0 rem(35) rem(144);
    }
  }

  &__img {
    flex: 0 0 auto;
    width: rem(190);
    height: rem(190);
    background-color: rgba($color: #6f8796, $alpha: 0.15);
    border-radius: 50%;
    overflow: hidden;

    @media (max-width: 991px) {
      position: absolute;
      left: 0;
      top: 0;
      width: rem(120);
      height: rem(120);
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &Col {
    display: flex;
    flex-direction: column;
    gap: rem(20);

    @media (max-width: 991px) {
      gap: rem(10);
    }

    &:nth-child(2) {
      flex: 0 0 rem(250);

      @media (max-width: 991px) {
        flex: 1 1 auto;
      }
    }
    &:nth-child(3) {
      margin: 0 0 0 auto;
      flex: 0 0 rem(370);

      @media (max-width: 991px) {
        flex: 1 1 auto;
        margin: 0;
      }
    }

    &__title {
    }
    &__text {
    }
    &__link {
      margin: auto 0 0;
      align-self: flex-start;
    }
  }
}
