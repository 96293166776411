@use 'sass:math';
@import '/src/scss/style.scss';
@import '/src/scss/mixins';
@import '/src/scss/colors-palette.scss';

.header {
  position: fixed;
  z-index: 10;
  width: 100%;
  backdrop-filter: blur(rem(50));
  background: rgba(148, 167, 178, 0.5);
  transition: transform 0.3s ease;

  &.hidden {
    transform: translate(0, -100%);
  }

  &.yellow {
    background: rgba($color: #b2a694, $alpha: 0.5);
  }

  &Body {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: rem(22) 0;

    transition: all 0.3s ease;

    @media (max-width: 991px) {
      padding: rem(17) 0;
    }

    &__logo {
      font-family: 'Sora', sans-serif;
      font-weight: 500;
      font-size: rem(22);
      line-height: 140%;
      color: #1d2326;

      transition: all $time $tr;

      &:hover {
        color: #fff;
      }

      @media (max-width: 991px) {
        font-size: rem(16);
        color: #fff;
      }
    }
    &__links {
      ul {
        display: flex;
        align-items: center;
        gap: rem(35);

        li {
          cursor: pointer;
          font-family: 'Sora', sans-serif;
          font-weight: 400;
          font-size: rem(19);
          line-height: 140%;
          color: #fff;

          transition: all 0.3s ease;

          &.active {
            color: #1d2326;
            cursor: default;
          }

          &:hover {
            color: #1d2326;
          }
        }
      }
    }
    &__burger {
      position: relative;
      width: rem(38);
      height: rem(10);

      span {
        position: absolute;
        width: 100%;
        height: rem(1);
        background-color: #fff;

        &:nth-child(1) {
          top: 0;
        }
        &:nth-child(2) {
          bottom: 0;
        }
      }
    }
  }
}

.menu {
  position: fixed;
  z-index: 11;
  top: 0;
  left: 0;
  right: 0;
  background: #94a7b2;

  transform: translate(0, -100%);
  opacity: 0;
  pointer-events: none;
  visibility: hidden;
  user-select: none;

  transition: all 0.3s ease;

  &.yellow {
    background: #b2a694;
  }

  &.open {
    transform: translate(0);
    opacity: 1;
    pointer-events: unset;
    visibility: visible;
    user-select: unset;
  }

  &Body {
    display: flex;
    flex-direction: column;
    height: 100vh;
  }
  &Top {
    padding: rem(10) 0 0;
    display: flex;
    justify-content: space-between;
    align-items: center;

    &__title {
      font-family: 'Sora', sans-serif;
      font-weight: 500;
      font-size: rem(16);
      line-height: 130%;
      color: #fff;
    }
    &__close {
      position: relative;
      width: rem(26);
      height: rem(26);

      span {
        position: absolute;
        width: 100%;
        height: rem(1);
        background-color: #fff;

        top: 50%;
        left: 50%;

        &:nth-child(1) {
          transform: translate(-50%, -50%) rotate(45deg);
        }
        &:nth-child(2) {
          transform: translate(-50%, -50%) rotate(-45deg);
        }
      }
    }
  }
  &Links {
    flex: 1 1 auto;
    border-top: rem(1) solid rgba(255, 255, 255, 0.5);
    margin: rem(46) 0 0;
    padding: rem(40) rem(30) 0;

    ul {
      display: flex;
      gap: rem(40);
      flex-direction: column;
      align-items: flex-start;

      li {
        font-weight: 500;
        font-size: rem(22);
        line-height: 115%;
        color: #fff;

        &.active {
          color: #1d2326;
        }
      }
    }
  }
  &Bottom {
    padding: 0 rem(30) rem(16);

    &__logo {
      font-family: 'Sora', sans-serif;
      font-weight: 500;
      font-size: rem(16);
      line-height: 130%;
      color: #fff;
    }
  }
}
