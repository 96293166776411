@use 'sass:math';
@import '/src/scss/style.scss';
@import '/src/scss/mixins';
@import '/src/scss/colors-palette.scss';

.text {
  font-family: 'Sora', sans-serif;
  font-weight: 400;
  font-size: rem(19);
  line-height: 140%;
  color: #14181a;

  @media (max-width: 991px) {
    font-size: rem(14);
  }

  &.blue {
    color: #6f8796;
  }
  &.black {
    color: #14181a;
  }
  &.white {
    color: #fff;
  }
  &.grey {
    color: #6f8796;
  }

  &.semiBold {
    font-weight: 600;
  }

  &.sm {
    font-size: rem(15);

    @media (max-width: 991px) {
      font-size: rem(10);
    }
  }
}
