@use 'sass:math';
@import '/src/scss/style.scss';
@import '/src/scss/mixins';
@import '/src/scss/colors-palette.scss';

.why {
  padding: rem(150) 0;
  background: #e9edef;

  @media (max-width: 991px) {
    padding: rem(55) 0;
  }

  &.yellow {
    background-color: #efede9;
  }

  &Body {
    max-width: rem(940);

    @media (max-width: 991px) {
      max-width: 100%;
    }

    &__title {
    }
    &__text {
      margin: rem(20) 0 0;

      @media (max-width: 991px) {
        margin: rem(10) 0 0;
      }
    }
  }
}
