@use 'sass:math';
@import '/src/scss/style.scss';
@import '/src/scss/mixins';
@import '/src/scss/colors-palette.scss';

.footer {
  position: relative;
  z-index: 1;
  background: #14181a;
  overflow: hidden;

  &__bg {
    position: absolute;
    z-index: -1;
    top: 0;
    right: 0;

    width: rem(1025);
    height: rem(800);

    pointer-events: none;
    user-select: none;

    @media (max-width: 991px) {
      width: rem(404);
      height: rem(315);
      right: auto;
      left: rem(10);
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
      object-position: top right;
    }
  }

  &__title {
    display: flex;
    flex-wrap: wrap;

    div {
      display: flex;
      flex-wrap: wrap;
      flex: 1 1 100%;

      i {
        display: block;
        width: rem(50);

        @media (max-width: 991px) {
          width: rem(20);
        }
      }
    }
  }

  &Top {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: rem(100);
    padding: rem(150) 0;

    @media (max-width: 991px) {
      gap: rem(50);
      padding: rem(55) 0;
    }
  }

  &Body {
    display: flex;
    gap: rem(20);
    padding: rem(40) 0;
    border-top: rem(1) solid rgba(111, 135, 150, 0.65);

    @media (max-width: 991px) {
      gap: rem(10);
      border-top: rem(1) solid rgba(111, 135, 150, 0.65);
      flex-direction: column;

      .footerCol {
        padding: rem(20) 0;

        &:not(:first-child) {
          border-top: rem(1) solid rgba(111, 135, 150, 0.65);
        }
      }
    }

    &__text {
      max-width: rem(435);

      @media (max-width: 991px) {
        max-width: 100%;
      }
    }

    &__contact {
      display: flex;
      flex-direction: column;
      gap: rem(15);

      @media (max-width: 991px) {
        gap: rem(12);
      }

      a {
        div {
          transition: all 0.3s ease;
        }
        &:hover {
          div {
            color: #fff;
          }
        }
      }

      &Social {
        display: flex;

        a {
          width: rem(29);
          height: rem(29);

          @media (max-width: 991px) {
            width: rem(29);
            height: rem(29);
          }

          svg {
            width: 100%;
            height: 100%;

            path {
              transition: all 0.3s ease;
            }
          }

          &:hover {
            svg {
              path {
                fill: #fff;
              }
            }
          }
        }
      }
    }

    &__links {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: rem(20);

      @media (max-width: 991px) {
        gap: rem(12);
      }

      a {
        div {
          transition: all 0.3s ease;
        }
        &:hover {
          div {
            color: #6f8796;
          }
        }
      }
    }
  }

  &Bottom {
    display: flex;
    gap: rem(20);
    padding: rem(25) 0;
    border-top: rem(1) solid rgba(111, 135, 150, 0.65);

    @media (max-width: 991px) {
      padding: rem(20) 0;
      justify-content: space-between;
    }

    .footerCol {
      @media (max-width: 991px) {
        flex: 0 1 auto;
      }
      div {
        color: rgba(111, 135, 150, 0.6);
      }
    }
  }

  &Col {
    flex: 0 0 calc(50% - rem(10));
    display: flex;
    flex-direction: column;
    gap: rem(15);

    &.row {
      flex-direction: row;
      justify-content: space-between;

      @media (max-width: 991px) {
        flex-direction: row-reverse;
      }
    }

    .policy {
      div {
        transition: all $time $tr;
      }
      &:hover {
        div {
          color: #6f8796;
        }
      }
    }
  }
}
