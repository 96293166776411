@use 'sass:math';
@import '/src/scss/style.scss';
@import '/src/scss/mixins';
@import '/src/scss/colors-palette.scss';

.heading {
  position: relative;
  padding: rem(130) 0 0;

  @media (max-width: 991px) {
    padding: rem(100) 0 rem(200);
  }
  @media (max-height: 700px) and (min-width: 1024px) {
    padding: rem(90) 0 0;
  }

  &__bg {
    position: absolute;
    inset: 0;
    // background-color: #94a7b2;
    // top: 0;
    // bottom: 0;
    pointer-events: none;
    user-select: none;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &Body {
    position: relative;
    z-index: 1;
    min-height: calc(100vh - rem(130));
    display: flex;
    flex-direction: column;
    // gap: rem(250);
    gap: rem(50);
    justify-content: space-between;

    @media (max-width: 991px) {
      gap: rem(50);
      min-height: calc(100vh - rem(300)) !important;
    }
    @media (max-height: 700px) and (min-width: 1024px) {
      min-height: calc(100vh - rem(90)) !important;
    }

    &__title {
      display: flex;
      flex-wrap: wrap;

      div {
        display: flex;
        flex-wrap: wrap;
        flex: 1 1 100%;

        i {
          display: block;
          width: rem(50);

          @media (max-width: 991px) {
            width: rem(20);
          }
        }
      }
    }
    &__bottom {
      margin: auto 0 0;
      position: relative;
      padding: rem(45) 0 rem(55) rem(50);
      border-left: rem(1) solid rgba(255, 255, 255, 0.5);

      @media (max-width: 991px) {
        border-top: rem(1) solid rgba(255, 255, 255, 0.5);
        border-left: none;
        padding: rem(20) 0 0;
      }

      &::before {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        width: 100vw;
        height: rem(1);
        background: rgba(255, 255, 255, 0.5);

        @media (max-width: 991px) {
          display: none;
        }
      }

      &Text {
        max-width: rem(930);
      }

      &Btn {
        margin: rem(25) 0 0;
      }
    }
  }
}
