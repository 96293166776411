@use 'sass:math';
@import '/src/scss/style.scss';
@import '/src/scss/mixins';
@import '/src/scss/colors-palette.scss';
.offering {
  background: #fff;
  padding: rem(150) 0;

  @media (max-width: 991px) {
    padding: rem(55) 0;
  }

  &Body {
    &__label {
      max-width: rem(630);

      @media (max-width: 991px) {
        max-width: 100%;
      }
    }
    &__title {
      margin: rem(20) 0 0;
      max-width: rem(630);

      @media (max-width: 991px) {
        margin: rem(10) 0 0;
        max-width: 100%;
      }
    }

    &__list {
      margin: rem(80) 0 0;

      @media (max-width: 991px) {
        margin: rem(35) 0 0;
      }

      &Title {
        flex: 0 0 rem(350);

        @media (max-width: 991px) {
          flex: 1 1 100%;
        }
      }

      li {
        display: flex;
        align-items: center;
        gap: rem(85);
        padding: rem(40) 0;

        border-top: rem(1) solid rgba(111, 135, 150, 0.5);

        @media (max-width: 991px) {
          flex-wrap: wrap;
          gap: rem(10);
          padding: rem(35) 0;
        }

        &:last-child {
          border-bottom: rem(1) solid rgba(111, 135, 150, 0.5);
        }
      }
    }
  }
}
